import React, { Fragment,useState,useEffect,useRef } from "react";
import { IoMdClose,IoMdCloudDone} from "react-icons/io";
import { BiError} from "react-icons/bi";

const SuccessErrorPopup = (props)=>{
    return(
        <div className="addbtnpopup">
            <div className="popupbackdrop">
                <div className="popupdelete mt-4">
                    <div className="clsbtnrow">
                        <button className="clsbtn" onClick={props.closeSuccessErrorMsg}><IoMdClose /></button>
                    </div>
                    <div className="popup-header mb-4 text-center">
                    <button className="delete-btn delete-popup-icon">
                    {
                        props.message.for==="success" ?
                        <span><IoMdCloudDone /></span>
                        :<span><BiError /></span>
                    }
                    </button>
                    </div> 
                    <div>
                        <p className={props.message.for==="success" ? "text-center delete-popup-text text-success " : "text-center delete-popup-text text-danger "}>{props.message.msg}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SuccessErrorPopup;