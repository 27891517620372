import React,{useState,useEffect} from 'react';
import axios from 'axios';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './components/Login';
import UserAccess from './pages/UserAccess';
import ViewPage from './pages/ViewPage';
import HomePage from './pages/IndexPage';
import UnderConstruction from './pages/Underconstruction';
import ProcessComponent from './pages/ProcessComponent';
import ErrorBoundary from './CommonComponents/ErrorBoundary';
import { useIdleTimer } from 'react-idle-timer'
import {IdealTimeForLogOut} from './variable'
import { useCookies } from "react-cookie";

function App() {
  const [cookies,setCookies,removeCookies] = useCookies();
  let initialCall = false;
  
// This fucntion is release the lock for document.
  const  releaseLockApi = async (props,e)=>{
    await axios.get(`${process.env.REACT_APP_BaseUrl}releaselocks`, 
      { headers: { "firstName":cookies.firstName,"lastName":cookies.lastName,"email":cookies.email  }}
    ).then(res => {
      if(props == "idl"){
        if(!initialCall){
          removeCookies();
        }
        initialCall = true;
        window?.location?.replace(`${window?.location?.origin}`);   
      }
      if(props == "close") {
        const message = "o/";
        (e || window.event).returnValue = message; //Gecko + IE
        return message;
      }
    }).catch(err => {
        console.log(err,"releaselocks");
    })
  }

  //on Idle the release lock function called.
  const onIdle = () => {
    if(window?.location?.pathname !=="/"){        
      releaseLockApi("idl");
    }
  }


  // on Active the release lock function called.
  const onActive = (event) => {
    if(window?.location?.pathname !=="/"){     
      releaseLockApi("idl");
    }
  }

// This is the react-idle-timer's functionality where this library will get the Idle time, 
// and many more funcitonality.
  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime
  } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 1000 * 60 * IdealTimeForLogOut,
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 2000,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  });

  // on Browser close and tab close 
  useEffect(() => {
    window.onunload = function(e){
      releaseLockApi("close",e)
     }
    window.onload = function(e){
      releaseLockApi("close",e)
     }     
    return () => {
      window.onunload = function(){};
      window.onload = function(){};
    }
  }, [])

  return (
    <Router>
      <Switch>        
        <Route exact path='/' component={Login} />
        <ErrorBoundary>
            <Route path='/home' component={HomePage} />
            <Route path='/user-access' component={UserAccess} />
            <Route path='/viewpage' component={ViewPage} />
            <Route path='/processview' component={ProcessComponent} />
            <Route path='/U-Construction' component={UnderConstruction} />
        </ErrorBoundary>
      </Switch>
    </Router>
  );
}

export default App;
