import { useState,useEffect } from "react";
import { FaCaretLeft } from 'react-icons/fa';
import { FcProcess } from "react-icons/fc";
import { TbArrowsSplit2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { Col, Row, Button } from "react-bootstrap";
import { PDFDocument , rgb} from 'pdf-lib';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import {CommaAndHypenAndIntegerReg} from '../variable';
import CommanSpinner from "./CommanSpinner";
import { useCookies } from "react-cookie";

function DocView(props) {
   const history = useHistory();
   const [popup,setPop]=useState(false);
   const [popupsplit,setPopupSplit]=useState(false);
   const [remainingPages,setRemainingPage] = useState([]);
   const [pageNumber,setPageNumber] = useState("");
   const [errorMsg,setErrorMsg] = useState("");   
   const [allPages,setAllPages] = useState([]);
   const [newPdf, setNewPdf] = useState("");
   const [loader,setLoader] = useState(false);
   const [allPagesAreSplited,setAllPagesAreSplited] = useState(false);
   const [childCreatedList,setChildCreatedList] = useState(0);
   const [creatdChildPdfList,setCreatdChildPdfList] = useState([]);
   const [cookies] = useCookies();
   const [parentPdfDeletePopup,setParentPdfDeletePopup] = useState(false);

   //Split popup open
   const handleClickOpen=()=>{
      setPop(!popup);
      setRemainingPage([]);
      setNewPdf("");
      setAllPages([]);
      setErrorMsg("");
      setPageNumber("");
   }
   //Split popup close
   const closePopup=()=>{
      setPop(false)
      setPopupSplit(false)
      setRemainingPage([]);
   }

   // Validate the Entered Value for Splitting page. and createPDF function called.
   const pageValidation =(createdChildPdfListTemp,totalPages)=>{
      let largePageNO = totalPages?.some((element,ind)=>{return element > props?.pdfTotalPages})
      let isZero = totalPages?.some((element, index) => {return element == 0});
      let repeated = totalPages?.some((element, index) => {return totalPages.indexOf(element) !== index});
      let childExist  = props?.chaildPdfPage?.some((element,ind)=>{return totalPages?.includes(element)});
      if(repeated){
         setErrorMsg("Do not repeat page number.");
      }
      else if(childExist){
         setErrorMsg("Page exist in child PDF.");
      }
      else if(isZero){
         setErrorMsg("Please Enter Valid Value.");
      }
      else if(largePageNO){
         setErrorMsg("Page number must be smaller than last page.");
      }else{
         createPDF(createdChildPdfListTemp[childCreatedList]?.childPdf);
      }      
   }

   // Split by page and make the range and add the list.
   const splitAndRange = (text,addToChild,sumPages)=>{
      if(text?.includes("-")){
         let dashSplit = [];
         let rangeValue = [];
         dashSplit = text?.split("-");
         if(dashSplit?.length > 1 ){
            if(parseInt(dashSplit[1]) > parseInt(dashSplit[0])){                       
               rangeValue = Array.from(Array(parseInt(dashSplit[1])), (_, index) => index + 1);
               rangeValue = rangeValue?.slice(parseInt(dashSplit[0])-1,);
               addToChild.push({"childPdf":[...rangeValue]})
               sumPages = [...sumPages,...rangeValue];
               return  {addToChild,sumPages};
            }else{
               setErrorMsg(`Please Enter Range Valid Start Value. :${dashSplit[0]}`);
              return "error greater";
            }
         }else{
            setErrorMsg("Please Enter Natural Formate Value. eg:  1,2,3-6");
            return "error Range";
         }
      }else{
         //todo  seprate page to split
         addToChild.push({"childPdf":[parseInt(text)]});
         sumPages = [...sumPages,parseInt(text)]; 
         return  {addToChild,sumPages};
      }
   }

   //On Click function validate input and create accordingly child pdf's.
   const handleClickOpenSplit=()=>{
      let createdChildPdfListTemp = [];
      let totalPages = [];
      let isError ="";
      if(!pageNumber){
         setRemainingPage([]);
         setErrorMsg("Please Enter Value.");
      }else if(pageNumber){
         const regex = new RegExp(CommaAndHypenAndIntegerReg);
         if(regex.test(pageNumber)){
            if(pageNumber?.includes(",")){
               //first element after splited
               let PagesArray = pageNumber?.split(",");
               PagesArray = PagesArray?.filter((el)=> el);
               if(PagesArray?.length>0){
                  PagesArray?.forEach((element,index)=>{
                     // if range in case to split
                     let returnValue = splitAndRange(element,createdChildPdfListTemp,totalPages);
                     if( typeof (returnValue) =="string"){
                        isError = returnValue;
                     }else{
                        createdChildPdfListTemp = returnValue.addToChild;
                        totalPages =returnValue.sumPages;
                     }
                  });
               }
            }else{
               // todo page if single check and 
               // if range in case to split
               let returnValue = splitAndRange(pageNumber,createdChildPdfListTemp,totalPages);
               if( typeof (returnValue) =="string"){
                  isError = returnValue;
               }else{
                  createdChildPdfListTemp = returnValue.addToChild;
                  totalPages = returnValue.sumPages;
               } 
               console.log(returnValue,"returnValue111")
            }   
         }else{    
            setErrorMsg("Please Enter Natural Formate Value. eg:  1,2,3-6 ");
         }
      }
      if(!isError ){
         pageValidation(createdChildPdfListTemp,totalPages);
         setCreatdChildPdfList(createdChildPdfListTemp);
      }
   }

   // For splitting added the page number.
   const onPageNumberAdd =(e)=>{
      setPageNumber(e.target.value);
      setErrorMsg("");
   }

   //++++++++++++ pdf creation++++++++
   // As per valid Input creating the child pdf.
   const createPDF = async (createPdfPages)=>{  
      // create copy the the existing file
        setLoader(true);
        let loadedFile = "";
        let existingFile =  new Promise((resolve, reject) => {  resolve(props?.uploadedPdf.arrayBuffer()); reject("console.log");
        }).then((res)=>{
         //  console.log(res,"arrayBuffer")
            let loads =  new Promise((resolve, reject) => {  resolve( new PDFDocument.load(res)); reject("console.log");
            }).then((res)=>{
            //   console.log(res,"load")
              loadedFile =  res
              if(loadedFile){
                let pdfObj = "";
                //pdf new empty file
                const pdfDoc   = new Promise((resolve, reject) => {  resolve(new PDFDocument.create()); reject("console.log");
                }).then((res)=>{
                  // console.log(res,"create")
                  pdfObj = res
                  //copy pages from existing pdf 
                  let copyPages  = [...createPdfPages];
                  let copyPagesLength = copyPages?.length ? copyPages?.length :0 ;
                  let pagesArray = [];
                  for (let i =0;i< copyPagesLength;i++){
                    let abc = new Promise((resolve, reject) => {  resolve( pdfObj.copyPages(loadedFile, [copyPages[i]-1])); reject("console.log");
                      }).then((res)=>{
                        // console.log("copyPages",pdfObj)
                        pagesArray.push(res);
                        if( i == (copyPagesLength - 1)){
                          setAllPages(pagesArray);
                          setNewPdf(pdfObj);
                        }
                      }).catch((er)=>{
                        setLoader(false);
                        console.log(er,"copy page error");
                     })
                  }
                }).catch((er)=>{
                  setLoader(false);
                  console.log(er,"pdf create")
               })
              }
            }).catch((err)=>{
               setLoader(false);
               console.log(err,"Load existing pdf")
            })
        }).catch((err)=>{
         setLoader(false);
         console.log(err,"ArrayBuffer");
        })
   }
   useEffect(()=>{
      //if any pages then make them save to empty pdf 
      if(allPages?.length > 0 && props?.currentPage =="parent"){
        let allPagesLength = allPages?.length;
        for (let i = 0;i< allPagesLength ;i++){
          let [val]  = allPages[i]
          newPdf.addPage(val); 
        }
        // save the page
        new Promise((resolve, reject) => {  resolve(newPdf.save());  reject("console.log");
        }).then((res)=>{
         // console.log(res,"rrrrrrrrrrrrr");
          let fileName = `${props?.uploadedPdf?.name}`
          let fileCreated =  new File([res], `${fileName}`, { type: "application/pdf" })
         //  upload the child pdf file.        
          const formdata = new FormData();
          formdata.append("id",props?.pdfObject?.pdfVal?.Id ? props?.pdfObject?.pdfVal?.Id :"");
          formdata.append("pages",[...creatdChildPdfList[childCreatedList].childPdf]);
          formdata.append("pdfFile",fileCreated);
          axios.post(`${process.env.REACT_APP_BaseUrl}splitpdf`,
            formdata , {headers: {'Content-Type': 'application/pdf',"Access-Control-Allow-Origin":"*", "firstName":cookies.firstName,"lastName":cookies.lastName,"email":cookies.email}}
          ).then((res)=>{
            //   console.log(res,"------","uploaded successful")
            setLoader(false);
            setAllPages([]);
            setChildCreatedList(childCreatedList+1);
            if((childCreatedList+1) == creatdChildPdfList?.length){
               setPopupSplit(true);
            }
          }).catch((err)=>{
            console.log(err,"splitpdf=====");
            setLoader(false);
            setErrorMsg("err- server error");
         })
        }).catch((err)=>{
         setLoader(false);
         console.log(err,"save")
        })
      }
      let allPagesList =  Array.from(Array(props?.pdfTotalPages), (_, index) => index + 1);
      const splitTheData = allPagesList.every((element) => props?.chaildPdfPage.includes(element));
      setAllPagesAreSplited(splitTheData)
   },[allPages])

   // If the child pdf are more one then this itteration happens.
   useEffect(()=>{
      if(childCreatedList ){
         if(childCreatedList  < creatdChildPdfList?.length){
            createPDF(creatdChildPdfList[childCreatedList].childPdf);
         }
      }
   },[childCreatedList]);
   //++++++++++++ pdf creation end++++++++

   //As per requirement the parent pdf will delete with conformation message.
   const parentPdfDeleteFromNiko =()=>{
      axios.delete(`${process.env.REACT_APP_BaseUrl}fax/${props?.pdfObject?.pdfVal?.Id}`,
         { headers: { 'Content-Type': 'application/x-www-form-urlencoded',"Access-Control-Allow-Origin":"*","firstName":cookies.firstName,"lastName":cookies.lastName,"email":cookies.email}}
      ).then((res)=>{
         //   console.log(res,"------","uploaded successful")
         setParentPdfDeletePopup(false);
         history.push({ pathname: '/home', state: {indexPageState:history?.location?.state?.indexPageState? history?.location?.state?.indexPageState:{alias:[],searchCallerId:"",pageNumber:1} } });
      }).catch((err)=>{
         console.log(err,"delete parent with niko pdf=====");
      })
   }

   // ProcessPage (child and parent)  called from Viewpage.
   const processPdf = ()=>{ 
      if(props?.currentPage == "parent"){
         history.push({ pathname: '/processview', state: {pdfVal:props?.pdfObject?.pdfVal,from:"ocr",indexPageState:history.location.state.indexPageState} })
      }else{   
         history.push({ pathname: '/processview', state: {parent:props?.pdfObject?.parent,child:props?.pdfObject?.child,from:"ocr",indexPageState:history.location.state.indexPageState,ind:props?.pdfObject?.ind} });
      }
   }

   return (
   <>
      <div>
         <Row className="mt-2">
            <ol className='breadcrum'>
               <li className='breadcrumb-item'><Link to={{pathname: "/home", state: {indexPageState:history?.location?.state?.indexPageState? history?.location?.state?.indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}>Inbox</Link></li>
               <li className='breadcrumb-item'>Document View </li>
            </ol>
         </Row>
         <Row className='mt-4'>
            <Col>
               <div>
                  <h2 className="semi-bold">Document View</h2>
               </div>
               <div className="semi-bold1">
                  {
                     props?.currentPage == "parent" ? 
                     `${props?.pdfObject.pdfVal.FaxId} | ${props?.pdfObject.pdfVal.ReceivedOn?.slice(0,10)} | ${props?.pdfObject.pdfVal.Alias} | ${props?.pdfObject.pdfVal.CallerId}`
                     :`${props?.pdfObject.parent.FaxId+`-${props?.pdfObject.ind+1}`} | ${props?.pdfObject.parent.ReceivedOn?.slice(0,10)} | ${props?.pdfObject.parent.Alias} | ${props?.pdfObject.parent.CallerId}`
                  }
               </div>
            </Col>
            <Col >
               <div className="d-flex justify-content-end text-align-right mb-2">
                  <Link className="backtoinbox" to={{pathname: "/home", state: {indexPageState:history?.location?.state?.indexPageState? history?.location?.state?.indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}><FaCaretLeft/>Back to Inbox</Link>
               </div>
               <div className="d-flex justify-content-end text-align-right">
                  {
                     props?.currentPage == "parent" && props?.pdfTotalPages > 1 && !allPagesAreSplited?
                        <Button className='docbtn'  onClick={handleClickOpen}>
                           <span className="justify-content-center" style={{display:"flex"}}>
                              <TbArrowsSplit2 />
                           </span>
                        </Button>
                        :""
                  }
                  {
                     props?.chaildPdfPage?.length == 0  ?
                     <Button className='docbtn' onClick={processPdf}>
                        <span className="justify-content-center" style={{display:"flex"}}>
                           <FcProcess className="highlight"/>
                        </span>
                     </Button>
                     :""
                  }
                  {
                     props?.currentPage == "parent" ?
                        <Button className='deletedocbtn' onClick={()=>setParentPdfDeletePopup(true)}>
                           <span className="justify-content-center" style={{display:"flex"}}>
                              <MdDelete />
                           </span>
                        </Button> 
                        :""
                  }
               </div>
            </Col>
         </Row>
      </div>
      <div>
         {
         popup?
         <div className="addbtnpopup">
            <div className="popupbackdrop">
               <div className="popup mt-4">
               <div className="clsbtnrow"><button className="clsbtn" onClick={closePopup}><IoMdClose /></button></div>
                  <div className="popup-header justify-content-center text-center mb-4">
                     <h4 className="bold">Split Document</h4>
                  </div>
                  <div className="popup-header justify-content-center text-center">
                     <p className="text-danger">{errorMsg}</p>
                  </div>   
                  {
                     <>
                     <p className="form-label justify-content-center text-center">Add pages and/or page ranges to create as child documents</p>
                        <div className="popup-header">
                           <input className="form-control split-popup mb-4" placeholder="Ex: 2, 3-5, 6" type="text" value={pageNumber} onChange={(e)=>onPageNumberAdd(e)}   />
                        </div>
                        <div className="popup-header justify-content-center text-center mt-4 mb-5">
                           <Button className="form-btn1 btn-width" onClick={handleClickOpenSplit}>Split Document</Button>
                        </div>
                     </>
                  }
                  {
                     loader?
                     <CommanSpinner spinner={loader} text={""} />
                     :""
                  }
               </div>
            </div>
         </div>
         :""
         }
      </div>
      <div>
         {
         popupsplit?
         <div className="addbtnpopup">
         <div className="popupbackdrop">
            <div className="popupsplit mt-4">
            <div className="clsbtnrow"><Link to={{pathname: "/home", state: {indexPageState:history?.location?.state?.indexPageState? history?.location?.state?.indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}><button className="clsbtn"><IoMdClose /></button></Link> </div>
               <div className="popup-header justify-content-center text-center mb-4">
                  <h4 className="bold">Split Document</h4>
               </div>
               <div className="popup-header justify-content-center text-center mt-5 mb-5 ">
                  <p className="split-success">You have successfully split the document.</p>
               </div>
               <div className="popup-header justify-content-center text-center mt-4 mb-5">
                  <Link to={{pathname: "/home", state: {indexPageState:history?.location?.state?.indexPageState? history?.location?.state?.indexPageState:{alias:[],searchCallerId:"",pageNumber:1} }}}>
                  <Button className="form-btn1 btn-width ">GO TO INBOX</Button></Link>
               </div>
            </div>
         </div>
         </div>
         :""
         }
      </div>
      {/* delete functionality popup */}
      {/* for parent delte with niko */}
      {
         parentPdfDeletePopup?
         <div  className="addbtnpopup">
            <div className="popupbackdrop-delete">
               <div className="popupdelete mt-4">
                  <div className="popup-header mt-4 mb-4 text-center">
                     <button className="delete-btn">
                        <span>
                           <MdDelete />
                        </span>
                     </button>
                  </div>
                  <div>
                     <p className="text-center">Are you sure you want to delete selected documents and associated child documents?</p>
                  </div>
                  <Row>
                     <Col md={6} className="popup-header  mt-4 mb-4 text-center">                        
                        <Button className="form-btn" style={{width:"80%",background:"#cecece"}} onClick={()=>setParentPdfDeletePopup(false)} >Cancel</Button>                        
                     </Col>
                     <Col md={6} className="popup-header  mt-4 mb-4 text-center">
                        <Button className="form-btn" style={{width:"80%",background:"#1C3765"}} onClick={()=>parentPdfDeleteFromNiko()}>Delete</Button>
                     </Col>
                  </Row>
               </div>
            </div>
         </div>
         :""
      }
   </>
   );
}
export default DocView;